import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  get (store, id) {
    Vue.$logger.debug('admin-order | Getting order id: ' + id)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/order/' + id

    return axios.get(ACTION_URL, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-order | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('order_not_found')
          } else {
            throw new Error('order_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-order', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  getAll (store, filter) {
    Vue.$logger.debug('admin-order | Getting all orders', filter)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/orders'

    return axios.get(ACTION_URL, { headers: store.getters['user/authHeader'](), params: filter })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-order | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else {
            throw new Error('order_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-order', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  getCsv (store, filter) {
    Vue.$logger.debug('admin-order | Getting CSV-list of all orders', filter)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/orders'

    return axios.get(ACTION_URL, { headers: { Accept: 'text/csv', ...store.getters['user/authHeader']() }, params: filter })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-order | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else {
            throw new Error('orders_get_csv_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-order', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  getStatistics (store, filter) {
    Vue.$logger.debug('admin-order | Getting all orders', filter)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/statistics'

    return axios.get(ACTION_URL, { headers: store.getters['user/authHeader'](), params: filter })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-order | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else {
            throw new Error('order_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-order', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  cancel (store, id, managerComment) {
    Vue.$logger.debug('admin-order | Cancelling order; id = ', id)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/order/' + id + '/cancel'

    return axios.patch(ACTION_URL, { manager_comment: managerComment }, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-order | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 400) {
            if (error.response.data.message === 'order must be in status new') {
              throw new Error('order_cancel_wrong_status')
            } else if (error.response.data.message === 'transaction already canceled') {
              throw new Error('order_cancel_transaction_already_canceled')
            } else {
              throw new Error('order_cancel_error')
            }
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('order_not_found')
          } else if (error.response.status === 409) {
            throw new Error('order_cancel_period_not_active')
          } else {
            throw new Error('order_cancel_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-order', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
