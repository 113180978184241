<template>
  <div class="pagination">
    <button class="pagination__button" :disabled="currentPage === 1" @click="firstPage()"><font-awesome-icon icon="angle-double-left" /></button>
    <button class="pagination__button" :disabled="currentPage === 1" @click="previousPage()"><font-awesome-icon icon="angle-left" /></button>
    <div class="pagination__page-select">
      <select v-model="currentPage">
        <option :value="item" v-for="(item, index) in Array.from({ length: pagesTotal }, (v, i) => (i + 1))" :key="index">{{ item }}</option>
      </select>
      <span>/&nbsp;{{ pagesTotal }}</span>
    </div>
    <button class="pagination__button" :disabled="currentPage === pagesTotal" @click="nextPage()"><font-awesome-icon icon="angle-right" /></button>
    <button class="pagination__button" :disabled="currentPage === pagesTotal" @click="lastPage()"><font-awesome-icon icon="angle-double-right" /></button>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: [
    'page',
    'pagesTotal',
    'pageSize',
    'itemsTotal',
    'onPageChange'
  ],
  computed: {
    currentPage: {
      get () {
        return this.page
      },
      set (value) {
        this.onPageChange(value)
      }
    }
  },
  methods: {
    nextPage () {
      if (this.page < this.pagesTotal) {
        this.currentPage = this.page + 1
      }
    },
    previousPage () {
      if (this.page > 1) {
        this.currentPage = this.page - 1
      }
    },
    firstPage () {
      this.currentPage = 1
    },
    lastPage () {
      this.currentPage = this.pagesTotal
    }
  },
  created: function () {
  },
  mounted: function () {
    this.currentPage = this.page || 1
  }
}
</script>

<style lang="css" scoped>
  .pagination {
    display: flex;
    align-items: center;
  }

  .pagination__button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    cursor: pointer;
    color: var(--main-color);
    transition: color 0.3s;
  }

  .pagination__button:hover {
    color: var(--accent-color);
  }

  .pagination__button[disabled] {
    color: #999;
    cursor: default;
  }

  .pagination__page-select {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 1.25;
  }

  .pagination__page-select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: right;
    border: none;
    background: none;
    padding-right: 32px;
    margin-right: -32px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    color: #333;
  }

  .pagination__page-select select:focus {
    outline: none;
  }

  .pagination__page-select select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  .pagination__page-select span {
    font-size: 16px;
    line-height: 1.25;
    color: #777;
  }
</style>
