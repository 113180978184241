<template>
  <div class="modal micromodal-slide" id="order-details" aria-hidden="true" v-if="data !== null">
    <div class="modal__overlay" tabindex="-1" data-micromodal-close>
      <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
        <div class="modal__wrapper">
          <header class="modal__header">
            <h2 class="modal__title">Информация о заказе <span v-if="!error">№{{ data.id }}</span></h2>
            <button class="modal__close" aria-label="Close modal" data-micromodal-close></button>
          </header>
          <main class="modal__content">
            <div class="alert alert--danger" v-if="error">
              <p v-html="error"></p>
            </div>
            <div v-if="!error" class="order-details">
              <div class="order-details__row">
                <span>ID:</span>
                <strong>{{ data.id }}</strong>
              </div>
              <div class="order-details__row">
                <span>Внешний код:</span>
                <strong>{{ $store.state.main.code }}:{{ data.id }}</strong>
              </div>
              <div class="order-details__row">
                <span>Дата создания:</span>
                <strong>{{ data.created_date | date }}</strong>
              </div>
              <div class="order-details__row">
                <span>Статус:</span>
                <strong>{{ getOrderStatusLabel(data.status) }}</strong>
              </div>
              <div v-if="data.order.params && data.order.params.email !== ''">
                <hr>
                <h3>Свойства заказа</h3>
                <div class="order-details__row">
                  <span>Email:</span>
                  <strong>{{ data.order.params.email }}</strong>
                </div>
              </div>
              <div v-if="data.order.cart && data.order.cart.length > 0">
                <hr>
                <h3>Состав заказа</h3>
                <div class="order-details__row">
                  <div class="order-details__cart">
                    <div v-for="(item, index) in data.order.cart" :key="index" class="order-details__cart-item">
                      <div class="order-details__cart-column order-details__cart-column--product-id" data-label="ID продукта"><span>{{ item.id }}</span></div>
                      <div class="order-details__cart-column order-details__cart-column--quantity" data-label="Кол-во"><span>{{ item.qty }}</span></div>
                      <div class="order-details__cart-column order-details__cart-column--face" data-label="Номинал">
                        <span v-if="!item.price.face" class="order-details__cart-column-empty-detail">&ndash;</span>
                        <span v-if="item.price.face">{{ item.price.face | numberFormat(0, '', ' ') }}</span>
                      </div>
                      <div class="order-details__cart-column order-details__cart-column--points" data-label="Снято"><span>{{ item.price.points | numberFormat(0, '', ' ') }}</span></div>
                      <div class="order-details__cart-column order-details__cart-column--statuses" data-label="Статусы">
                        <span v-if="!item.statuses || item.statuses.length === 0" class="order-details__cart-column-empty-detail">&ndash;</span>
                        <div class="order-details__cart-item-statuses" v-if="item.statuses">
                          <div v-for="(itemStatus, indexStatus) in item.statuses" :key="indexStatus" class="order-details__cart-item-status">
                            <span class="order-details__cart-item-status" :class="' order-details__cart-item-status--' + getSubOrderStatusColor(itemStatus.status)" v-html="getSubOrderStatusLabel(itemStatus.status)"></span>
                            <div class="order-details__cart-item-status-description" v-if="itemStatus.error_message">{{ itemStatus.error_message }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import adminOrder from '../api/admin-order'
import appError from 'base/modules/app-error'
import utils from 'base/modules/utils'
import MicroModal from 'micromodal'

export default {
  name: 'AdminOrderDetails',
  data () {
    return {
      data: null,
      error: ''
    }
  },
  filters: {
    numberFormat: function (number, decimals, decimalPoint, thousandsSeparator) {
      return utils.numberFormat(number, decimals, decimalPoint, thousandsSeparator)
    },
    date: function (date) {
      const dateObj = new Date(date)
      return utils.formatDateTime(dateObj)
    }
  },
  methods: {
    showOrderDetails: function (id) {
      adminOrder.get(this.$store, id).then(response => {
        this.error = ''
        this.data = response.data
        this.$nextTick(() => {
          this.showModal()
        })
      }).catch((error) => {
        this.error = appError.getMessage(error.message)
        this.data = {}
        Vue.$logger.debug('AdminOrderDetails | adminOrder.get | ошибка: ', error)
        this.$nextTick(() => {
          this.showModal()
        })
      })
    },
    getOrderStatusLabel (status) {
      switch (status) {
        case 'new':
          status = 'Ожидает подтверждения'
          break
        case 'pending':
          status = 'Обрабатывается'
          break
        case 'processing':
          status = 'Обрабатывается'
          break
        case 'checking':
          status = 'Обрабатывается'
          break
        case 'done':
          status = 'Завершён'
          break
        case 'canceled':
          status = 'Отменён'
          break
      }
      return status
    },
    getSubOrderStatusLabel (status) {
      switch (status) {
        case 'ok':
          status = 'Отгружен'
          break
        case 'pending':
          status = 'Обрабатывается'
          break
        case 'error':
          status = 'Ошибка'
          break
        case 'preparing':
          status = 'Проверяется'
          break
        case 'executing':
          status = 'Обрабатывается'
          break
        case 'delayed':
          status = 'Отложенный'
          break
        case 'cancel':
          status = 'Отменён'
          break
      }
      return status
    },
    getOrderStatusColor (status) {
      let color
      switch (status) {
        case 'done':
          color = 'green'
          break
        case 'error':
          color = 'red'
          break
        case 'cancel':
          color = 'grey'
          break
        case 'new':
        case 'pending':
        case 'processing':
        case 'checking':
          status = 'black'
          break
      }
      return color
    },
    getSubOrderStatusColor (status) {
      let color
      switch (status) {
        case 'ok':
          color = 'green'
          break
        case 'error':
          color = 'red'
          break
        case 'cancel':
          color = 'grey'
          break
        case 'pending':
        case 'preparing':
        case 'executing':
        case 'delayed':
          color = 'black'
          break
      }
      return color
    },
    showModal: function () {
      MicroModal.show('order-details', {
        onShow: modal => {
          document.body.style.position = 'fixed'
          document.body.style.width = '100%'
        },
        onClose: modal => {
          document.body.style.position = 'initial'
          document.body.style.width = 'auto'
        },
        openTrigger: 'data-micromodal-open',
        closeTrigger: 'data-micromodal-close',
        disableScroll: true,
        disableFocus: false,
        awaitCloseAnimation: false,
        debugMode: true
      })
    },
    closeModal: function () {
      MicroModal.close('order-details')
    }
  },
  created: function () {
  },
  mounted: function () {
    this.$on('showOrderDetails', event => {
    })
  }
}
</script>

<style lang="css">
  .order-details {
    font-size: 14px;
  }

  .order-details__cart {
    margin-bottom: 20px;
  }

  .order-details__cart-item {
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 3px solid #f6f6f6;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .order-details__cart-column {
    padding: 5px 15px;
  }

  .order-details__cart-column + .order-details__cart-column {
    border-top: 1px solid #fafafa;
  }

  .order-details__cart-column:before {
    content: attr(data-label);
    display: block;
    color: #999;
    font-size: 12px;
  }

  .order-details__cart-column > span {
    display: block;
  }

  .order-details__cart-column--uid > span {
    font-weight: normal;
    font-size: 20px;
    line-height: 1.2;
  }

  .order-details__cart-column--face > span,
  .order-details__cart-column--points > span {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    white-space: nowrap;
  }

  .order-details__cart-column--detail > span {
    font-size: 12px;
  }

  .order-details__cart-column hr {
    border-bottom: 1px solid #f0f0f0;
    margin: 0.5em 0;
  }

  .order-details__cart-item--canceled .order-details__cart-column span {
    color: #bbb;
  }

  .order-details__cart-column span.order-details__cart-column-empty-detail {
    color: #999;
  }

  .order-details__cart-item-status {
    font-size: 12px;
    padding: 2px 0;
  }

  .order-details__cart-item-status + .order-details__cart-item-status {
    border-top: 1px solid #f0f0f0;
  }

  .order-details__cart-item-status--green {
    color: #090;
  }

  .order-details__cart-item-status--red {
    color: #c00;
  }

  .order-details__cart-item-status--grey {
    color: #666;
  }

  .order-details__cart-item-status--default {
    color: #333;
  }

  @media (min-width: 640px) {
    .order-details__cart {
      display: table;
      width: 100%;
      max-width: 1000px;
      margin-top: 40px;
    }

    .order-details__cart-item {
      display: table-row;
    }

    .order-details__cart-item:hover {
      background-color: #f8f8f8;
    }

    .order-details__cart-item:hover .order-details__cart-column,
    .order-details__cart-item:hover + .order-details__cart-item .order-details__cart-column {
      border-top-color: var(--main-color);
    }

    .order-details__cart-item:last-child .order-details__cart-column {
      border-bottom: 1px solid #fafafa;
    }

    .order-details__cart-item:last-child:hover .order-details__cart-column {
      border-bottom-color: var(--main-color);
    }

    .order-details__cart-item .order-details__cart-column:before {
      display: none;
    }

    .order-details__cart-item:first-child .order-details__cart-column:before {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 110%;
      padding: 0 15px;
    }

    .order-details__cart-column {
      display: table-cell;
      position: relative;
    }

    .order-details__cart-column--product-id {
      border-top: 1px solid #fafafa;
    }

    .order-details__cart-column--face,
    .order-details__cart-column--points {
      text-align: right;
    }

    .order-details__cart-column.order-details__cart-column--statuses {
      max-width: 150px;
    }
  }
</style>
