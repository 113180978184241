<template>
  <div>
    <div class="alert alert--danger" v-if="error">
      <p v-html="error"></p>
    </div>
    <form class="users-list-filter">
      <div class="users-list-filter__field users-list-filter__field--uid">
        <label>Идентификатор</label>
        &nbsp;
        <div class="users-list-filter__field-container">
          <input class="form-field form-field--large form-field--full-width" type="text" v-model="filter.uid" placeholder="">
        </div>
      </div>

      <div
        class="users-list-filter__field users-list-filter__field--uid"
        v-for="(param, indexParam) in filterParams"
        :key="indexParam"
      >
        <label>{{ param.label }}</label>
        &nbsp;
        <div class="users-list-filter__field-container">
          <input class="form-field form-field--large form-field--full-width" type="text" v-model="filter[param.name]" placeholder="">
        </div>
      </div>

      <div class="users-list-filter__buttons">
        <button type="submit" class="button button--large button--rounded" @click="applyFilter()">Применить</button>
        <button type="reset" class="button button--large button--rounded button--transparent" @click="reset()">Сбросить</button>
      </div>
    </form>
    <div class="users-list" v-if="list.items.length > 0">
      <div v-for="(item, index) in list.items" :key="index" class="users-list__item" :class="{ 'users-list__item--canceled': item.canceled_at !== null }">
        <div class="users-list__column users-list__column--uid" data-label="UID"><span><a href="" title="Показать информацию о пользователе" @click.prevent="showUserDetails(item.uid)">{{ item.uid }}</a></span></div>
        <div class="users-list__column users-list__column--points" :class="{ 'users-list__column--green': item.points > 0, 'users-list__column--red': item.points < 0 }" data-label="Баллы"><span>{{ item.points | numberFormat(0, '', ' ') }}</span></div>

        <div
          v-for="(param, indexParam) in listParams"
          :key="indexParam"
          :class="'users-list__column ' + 'users-list__column--' + param.name.replace(/_/g, '-')"
          :data-label="param.label"
        >
          <div v-if="param.type === 'number'">
            <span v-if="item[param.name] > 0">{{ item[param.name] | numberFormat(0, '', ' ') }}</span>
            <span v-if="item[param.name] === 0">&ndash;</span>
          </div>
          <div v-else>
            <span>{{ item[param.name] }}</span>
          </div>
        </div>

        <div class="users-list__column users-list__column--controls" data-label="">
          <div class="users-list__controls">
            <router-link class="users-list__control users-list__control--edit" :to="{ name: 'AdminUserEdit', params: { userId: item.uid } }"><font-awesome-icon icon="pencil-alt" /><span>Редактировать</span></router-link>
            <button class="users-list__control users-list__control--activate" v-if="item.active === 0" @click="activate(item)"><font-awesome-icon icon="power-off" /><span>Активировать</span></button>
            <button class="users-list__control users-list__control--deactivate" v-if="item.active === 1" @click="deactivate(item)"><font-awesome-icon icon="ban" /><span>Деактивировать</span></button>
          </div>
        </div>
      </div>
    </div>

    <div class="users-list" v-if="list.items.length === 0">Список пользователей пуст</div>

    <div class="users-list__bottom" v-if="list.items.length > 0">
      <div class="users-list__csv">
        <button class="button button--small button--rounded" @click="downloadCsv" :disabled="loadingCsv">Скачать CSV</button>
      </div>

      <div class="users-list__pagination">
        <Pagination :page="list.page" :pagesTotal="list.pagesTotal" :pageSize="list.pageSize" :itemsTotal="list.itemsTotal" :onPageChange="pageChanged" />
      </div>
    </div>

    <AdminUserDetails ref="adminUserDetails" />
  </div>
</template>

<script>
import Vue from 'vue'
import adminUser from '../api/admin-user'
import Swal from 'sweetalert2'
import appError from 'base/modules/app-error'
import utils from 'base/modules/utils'
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'
import AdminUserDetails from 'base/components/AdminUserDetails'
import Pagination from 'base/components/Pagination'

Vue.component('datepicker', Datepicker)

export default {
  name: 'AdminUsersList',
  props: {
    listParams: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  components: {
    AdminUserDetails,
    Pagination
  },
  data () {
    const filter = {
      uid: ''
    }

    this.listParams.filter(item => !(item.filter === false)).forEach(item => {
      filter[item.name] = ''
    })

    return {
      ru: ru,
      filter,
      list: {
        page: 0,
        pagesTotal: 0,
        pageSize: 0,
        itemsTotal: 0,
        items: []
      },
      pagination: {
        page: 1,
        pageSize: 20
      },
      loading: false,
      loadingCsv: false,
      error: ''
    }
  },
  computed: {
    filterParams () {
      return this.listParams.filter(item => !(item.filter === false))
    }
  },
  filters: {
    numberFormat: function (number, decimals, decimalPoint, thousandsSeparator) {
      return utils.numberFormat(number, decimals, decimalPoint, thousandsSeparator)
    }
  },
  methods: {
    getData () {
      this.loading = true

      adminUser.getAll(this.$store, this.getFilter()).then(response => {
        this.loading = false
        this.list.page = response.data.page
        this.list.pagesTotal = response.data.pages_total
        this.list.pageSize = response.data.page_size
        this.list.itemsTotal = response.data.items_total

        if (response.data.items !== null) {
          this.list.items = response.data.items.map(item => {
            item.show = false
            return item
          })
        } else {
          this.list.items = []
        }
      }).catch((error) => {
        this.loading = false
        this.error = appError.getMessage(error.message)

        Vue.$logger.debug('AdminUsersList | adminUser.getAll | ошибка: ', error)
      })
    },
    getFilter () {
      const filter = {
        limit: this.pagination.pageSize,
        offset: (this.pagination.page - 1) * this.pagination.pageSize
      }

      if (this.filter.uid !== '') {
        filter.uid = this.filter.uid
      }

      this.filterParams.forEach(param => {
        if (this.filter[param.name] !== '') {
          filter[param.name] = this.filter[param.name]
        }
      })

      return filter
    },
    applyFilter () {
      this.resetPagination()
      this.getData()
    },
    resetPagination () {
      this.pagination = {
        page: 1,
        pageSize: 20
      }
    },
    resetFilter () {
      this.filterParams.forEach(param => {
        this.filter[param.name] = ''
      })

      this.filter.uid = ''
    },
    reset () {
      this.resetFilter()
      this.resetPagination()

      this.getData()
    },
    pageChanged (page) {
      this.$router.push({ query: { page } }).catch(()=>{})
    },
    downloadCsv () {
      const currentFilter = { ...this.getFilter() }
      currentFilter['limit'] = 100000
      currentFilter['offset'] = 0
      this.loadingCsv = true
      adminUser.getAllCsv(this.$store, currentFilter).then(response => {
        this.loadingCsv = false
        utils.downloadFile(response.data, 'users.csv')
      }).catch((error) => {
        this.loadingCsv = false
        this.error = appError.getMessage(error.message)

        Vue.$logger.debug('AdminUsersList | adminUser.getAllCsv | ошибка: ', error)
      })
    },
    deactivate (user) {
      adminUser.deactivate(this.$store, user.uid).then(() => {
        Swal.fire({
          title: 'Пользователь деактивирован',
          type: 'success',
          confirmButtonText: 'OK',
          confirmButtonClass: 'button button--confirm button--rounded button--large button--margined',
          buttonsStyling: false
        })
        this.getData()
      }).catch((error) => {
        this.error = appError.getMessage(error.message)

        Vue.$logger.debug('AdminUsersList | adminUser.deactivate | ошибка: ', error)
      })
    },
    activate (user) {
      adminUser.activate(this.$store, user.uid).then(() => {
        Swal.fire({
          title: 'Пользователь активирован',
          type: 'success',
          confirmButtonText: 'OK',
          confirmButtonClass: 'button button--confirm button--rounded button--large button--margined',
          buttonsStyling: false
        })
        this.getData()
      }).catch((error) => {
        this.error = appError.getMessage(error.message)

        Vue.$logger.debug('AdminUsersList | adminUser.activate | ошибка: ', error)
      })
    },
    showUserDetails (id) {
      this.$refs.adminUserDetails.showUserDetails(id)
    },
    afterMounted () {
    }
  },
  watch: {
    '$route.query.page': {
      handler (page) {
        this.pagination.page = page
        this.getData()
      },
      immediate: true
    }
  },
  created: function () {
    this.getData()
  },
  mounted: function () {
    this.afterMounted()
  }
}
</script>

<style lang="css" scoped>
.users-list-filter {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}

.users-list-filter__field {
  margin-bottom: 10px;
}

.users-list-filter__field-container + .users-list-filter__field-container {
  margin-top: 10px;
}

.users-list-filter__field label {
  white-space: nowrap;
}

.users-list-filter__buttons {
  display: flex;
  justify-items: center;
  margin-left: -6px;
  margin-right: -6px;
  margin-bottom: 10px;
}

.users-list-filter__buttons button {
  margin-left: 6px;
  margin-right: 6px;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
}

.users-list {
  margin-bottom: 20px;
}

.users-list__item {
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 3px solid #f6f6f6;
  padding-top: 2px;
  padding-bottom: 2px;
}

.users-list__column {
  padding: 5px 15px;
}

.users-list__column + .users-list__column {
  border-top: 1px solid #fafafa;
}

.users-list__column:before {
  content: attr(data-label);
  display: block;
  color: #999;
  font-size: 12px;
}

.users-list__column > span {
  display: block;
}

.users-list__column--uid > span {
  font-weight: normal;
  font-size: 20px;
  line-height: 1.2;
}

.users-list__column--points > span {
  font-weight: 600;
  font-size: 22px;
  line-height: 1.2;
  white-space: nowrap;
}

.users-list__column--controls {
  padding: 0;
}

.users-list__controls {
  display: flex;
  align-items: stretch;
  background-color: #fefefe;
  margin-top: -1px;
  min-height: 44px;
}

.users-list__control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: #fafafa;
  cursor: pointer;
  padding: 10px 0;
  font-size: 16px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.2s, background-color 0.2s;
}

.users-list__control:only-child {
  width: 100%;
}

.users-list__control--edit {
  color: #499;
}

.users-list__control--activate {
  color: #090;
}

.users-list__control--deactivate {
  color: #c00;
}

.users-list__control {
  text-decoration: none;
}

.users-list__control span {
  color: #333;
  transition: color 0.2s;
  margin-left: 8px;
}

.users-list__control:hover {
  color: #fff;
}

.users-list__control--edit:hover {
  background-color: #499;
  color: #fff;
}

.users-list__control--activate:hover {
  background-color: #090;
  color: #fff;
}

.users-list__control--deactivate:hover {
  background-color: #c00;
  color: #fff;
}

.users-list__control:hover span {
  color: #fff;
}

.users-list__column hr {
  border-bottom: 1px solid #f0f0f0;
  margin: 0.5em 0;
}

.users-list__pagination {
  margin-bottom: 10px;
}

.users-list__csv {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .users-list-filter__field {
    display: flex;
    align-items: center;
  }

  .users-list-filter__field label {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .users-list-filter__field-container + .users-list-filter__field-container {
    margin-top: 0;
    margin-left: 10px;
  }
}

@media (min-width: 800px) {
  .users-list-filter {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
  }

  .users-list-filter__field {
    margin-left: 8px;
    margin-right: 8px;
  }

  .users-list-filter__field--uid {
    max-width: 340px;
  }

  .users-list-filter__field:last-of-type {
    margin-right: auto;
  }

  .users-list-filter__buttons button {
    padding-left: 20px;
    padding-right: 20px;
  }

  .users-list {
    display: table;
    width: 100%;
    max-width: 1000px;
    margin-top: 40px;
  }

  .users-list__item {
    display: table-row;
  }

  .users-list__item:hover {
    background-color: #f8f8f8;
  }

  .users-list__item:hover .users-list__column,
  .users-list__item:hover + .users-list__item .users-list__column {
    border-top-color: var(--main-color);
  }

  .users-list__item:last-child .users-list__column {
    border-bottom: 1px solid #fafafa;
  }

  .users-list__item:last-child:hover .users-list__column {
    border-bottom-color: var(--main-color);
  }

  .users-list__item .users-list__column:before {
    display: none;
  }

  .users-list__item:first-child .users-list__column:before {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 110%;
    padding: 0 15px;
  }

  .users-list__column {
    display: table-cell;
    position: relative;
  }

  .users-list__column--uid {
    border-top: 1px solid #fafafa;
  }

  .users-list__column--points {
    text-align: right;
  }

  .users-list__column--active-tasks-count {
    text-align: right;
  }

  .users-list__controls {
    margin-left: auto;
    width: 320px;
  }

  .users-list__controls--empty {
    display: flex;
  }

  .users-list__control {
    flex-flow: column;
    padding: 8px 20px;
  }

  .users-list__control span {
    margin-left: 0;
  }

  .users-list__bottom {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .users-list__pagination {
    margin-bottom: 0;
  }

  .users-list__csv {
    margin-left: auto;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .users-list__controls {
    margin-left: auto;
    width: 380px;
  }

  .users-list__controls--empty {
    display: flex;
  }

  .users-list__control {
    flex-flow: row;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .users-list__control span {
    margin-left: 8px;
  }

  .users-list-filter__buttons button {
    padding-left: 30px;
    padding-right: 30px;
  }
}
</style>
