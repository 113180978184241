<template>
  <main>
    <div class="container">
      <div class="container__page">
        <div class="container__content">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-8 col-xl-7">
              <ThisComponent></ThisComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AdminUserEdit'

ThisComponent.mounted = async function () {
  this.$store.commit('app/updatePageClass', 'page--admin-user-edit')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  },
  data: () => ({
  })
}
</script>
