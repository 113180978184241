<template>
  <div>
    <div class="alert alert--danger" v-if="error">
      <p v-html="error"></p>
    </div>
    <form class="transactions-filter">
      <div class="transactions-filter__field transactions-filter__field--uid">
        <label>Идентификатор</label>
        &nbsp;
        <div class="transactions-filter__field-container">
          <input class="form-field form-field--large form-field--full-width" type="text" v-model="filter.searchUid" placeholder="">
        </div>
      </div>
      <div class="transactions-filter__field transactions-filter__field--date">
        <label>Дата</label>
        <div class="transactions-filter__field-container">
          <datepicker
            input-class="form-field form-field--large form-field--full-width"
            calendar-class="form-calendar"
            placeholder="с"
            :language="ru"
            full-month-name
            format="dd.MM.yyyy"
            monday-first
            v-model="filter.dateFrom"
          />
        </div>
        <div class="transactions-filter__field-container">
          <datepicker
            input-class="form-field form-field--large form-field--full-width"
            calendar-class="form-calendar"
            placeholder="по"
            :language="ru"
            full-month-name
            format="dd.MM.yyyy"
            monday-first
            v-model="filter.dateTo"
          />
        </div>
      </div>
      <div class="transactions-filter__buttons">
        <button type="submit" class="button button--large button--rounded" @click="applyFilter()">Применить</button>
        <button type="reset" class="button button--large button--rounded button--transparent" @click="reset()">Сбросить</button>
      </div>
    </form>
    <div class="transactions-list" v-if="list.items.length > 0">
      <div v-for="(item, index) in list.items" :key="index" class="transactions-list__item" :class="{ 'transactions-list__item--canceled': item.canceled_at !== null }">
        <div class="transactions-list__column transactions-list__column--id" data-label="ID"><span>{{ item.id }}</span></div>
        <div class="transactions-list__column transactions-list__column--uid" data-label="UID"><span><a href="" title="Показать информацию о пользователе" @click.prevent="showUserDetails(item.uid)">{{ item.uid }}</a></span></div>
        <div class="transactions-list__column transactions-list__column--points" :class="{ 'transactions-list__column--green': item.points > 0, 'transactions-list__column--red': item.points < 0 }" data-label="Баллы"><span>{{ item.points | numberFormat(0, '', ' ') }}</span></div>
        <div class="transactions-list__column transactions-list__column--date" data-label="Дата"><span>{{ item.created_at | date }}</span></div>
        <div class="transactions-list__column transactions-list__column--detail" data-label="Информация">
          <span>
            <span v-if="(item.created_comment === '' || item.created_comment === null) && item.canceled_at === null" class="transactions-list__column-empty-detail">&ndash;</span>
            <span v-if="item.order_id !== '' && item.order_id !== null"><a href="" title="Показать информацию о заказе" @click.prevent="showOrderDetails(item.order_id)"><b>Заказ №{{ item.order_id }}</b></a></span>
            <span v-if="(item.order_id === '' || item.order_id === null) && item.created_comment !== '' && item.created_comment !== null"><b>{{ item.created_comment }}</b></span>
            <span v-if="item.canceled_at !== null" class="transactions-list__column-canceled-detail">
              <hr>
              <b>Информация об отмене:</b><br>
              Комментарий: <b>{{ item.canceled_comment }}</b><br>
              Дата: <b>{{ item.canceled_at | date }}</b>
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="transactions-list" v-if="list.items.length === 0">Список транзакций пуст</div>

    <div class="transactions-list__bottom" v-if="list.items.length > 0">
      <div class="transactions-list__csv">
        <button class="button button--small button--rounded" @click="downloadCsv" :disabled="loadingCsv">Скачать CSV</button>
      </div>

      <div class="transactions-list__pagination">
        <Pagination :page="list.page" :pagesTotal="list.pagesTotal" :pageSize="list.pageSize" :itemsTotal="list.itemsTotal" :onPageChange="pageChanged" />
      </div>
    </div>

    <AdminOrderDetails ref="adminOrderDetails" />
    <AdminUserDetails ref="adminUserDetails" />
  </div>
</template>

<script>
import Vue from 'vue'
import adminTransaction from '../api/admin-transaction'
import appError from 'base/modules/app-error'
import utils from 'base/modules/utils'
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'
import AdminOrderDetails from 'base/components/AdminOrderDetails'
import AdminUserDetails from 'base/components/AdminUserDetails'
import Pagination from 'base/components/Pagination'

Vue.component('datepicker', Datepicker)

export default {
  name: 'AdminTransactions',
  components: {
    AdminOrderDetails,
    AdminUserDetails,
    Pagination
  },
  data () {
    return {
      ru: ru,
      filter: {
        searchUid: '',
        dateFrom: '',
        dateTo: ''
      },
      list: {
        page: 0,
        pagesTotal: 0,
        pageSize: 0,
        itemsTotal: 0,
        items: []
      },
      pagination: {
        page: 1,
        pageSize: 20
      },
      loading: false,
      loadingCsv: false,
      error: ''
    }
  },
  filters: {
    numberFormat: function (number, decimals, decimalPoint, thousandsSeparator) {
      return utils.numberFormat(number, decimals, decimalPoint, thousandsSeparator)
    },
    date: function (date) {
      const dateObj = new Date(date)
      return utils.formatDateTime(dateObj)
    }
  },
  methods: {
    getData () {
      this.loading = true

      adminTransaction.get(this.$store, this.getFilter()).then(response => {
        this.loading = false
        this.list.page = response.data.page
        this.list.pagesTotal = response.data.pages_total
        this.list.pageSize = response.data.page_size
        this.list.itemsTotal = response.data.items_total

        this.list.items = response.data.items.map(item => {
          item.show = false
          return item
        })
      }).catch((error) => {
        this.loading = false
        this.error = appError.getMessage(error.message)

        Vue.$logger.debug('AdminTransactions | adminTransaction.get | ошибка: ', error)
      })
    },
    getFilter () {
      const filter = {
        limit: this.pagination.pageSize,
        offset: (this.pagination.page - 1) * this.pagination.pageSize
      }

      if (this.filter.searchUid !== '') {
        filter.uid = this.filter.searchUid
      }

      if (this.filter.dateFrom !== '') {
        filter.date_from = utils.formatDateForRequest(this.filter.dateFrom)
      }

      if (this.filter.dateTo !== '') {
        filter.date_to = utils.formatDateForRequest(this.filter.dateTo)
      }

      return filter
    },
    applyFilter () {
      this.resetPagination()
      this.getData()
    },
    resetPagination () {
      this.pagination = {
        page: 1,
        pageSize: 20
      }
    },
    resetFilter () {
      this.filter = {
        searchUid: '',
        dateFrom: '',
        dateTo: ''
      }
    },
    reset () {
      this.resetFilter()
      this.resetPagination()

      this.getData()
    },
    pageChanged (page) {
      this.pagination.page = page
      this.getData()
    },
    downloadCsv () {
      const currentFilter = { ...this.getFilter() }
      currentFilter['limit'] = 100000
      currentFilter['offset'] = 0
      this.loadingCsv = true
      adminTransaction.getCsv(this.$store, currentFilter).then(response => {
        this.loadingCsv = false
        utils.downloadFile(response.data, 'transactions.csv')
      }).catch((error) => {
        this.loadingCsv = false
        this.error = appError.getMessage(error.message)

        Vue.$logger.debug('AdminTransactions | adminTransaction.getCsv | ошибка: ', error)
      })
    },
    showOrderDetails: function (id) {
      this.$refs.adminOrderDetails.showOrderDetails(id)
    },
    showUserDetails: function (id) {
      this.$refs.adminUserDetails.showUserDetails(id)
    },
    getOrderStatusLabel (status) {
      switch (status) {
        case 'new':
          status = 'Ожидает подтверждения'
          break
        case 'pending':
          status = 'Обрабатывается'
          break
        case 'processing':
          status = 'Обрабатывается'
          break
        case 'checking':
          status = 'Обрабатывается'
          break
        case 'done':
          status = 'Завершён'
          break
        case 'canceled':
          status = 'Отменён'
          break
      }
      return status
    },
    afterMounted: function () {
    }
  },
  created: function () {
    this.getData()
  },
  mounted: function () {
    this.afterMounted()
  }
}
</script>

<style lang="css" scoped>
  .transactions-filter {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
  }

  .transactions-filter__field {
    margin-bottom: 10px;
  }

  .transactions-filter__field-container + .transactions-filter__field-container {
    margin-top: 10px;
  }

  .transactions-filter__field label {
    white-space: nowrap;
  }

  .transactions-filter__buttons {
    display: flex;
    justify-items: center;
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: 10px;
  }

  .transactions-filter__buttons button {
    margin-left: 6px;
    margin-right: 6px;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1;
  }

  .transactions-list {
    margin-bottom: 20px;
  }

  .transactions-list__item {
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 3px solid #f6f6f6;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .transactions-list__column {
    padding: 5px 15px;
  }

  .transactions-list__column + .transactions-list__column {
    border-top: 1px solid #fafafa;
  }

  .transactions-list__column:before {
    content: attr(data-label);
    display: block;
    color: #999;
    font-size: 12px;
  }

  .transactions-list__column > span {
    display: block;
  }

  .transactions-list__column--uid > span {
    font-weight: normal;
    font-size: 20px;
    line-height: 1.2;
  }

  .transactions-list__column--points > span {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
    white-space: nowrap;
  }

  .transactions-list__column--green > span {
    color: #090;
  }

  .transactions-list__column--red > span {
    color: #c00;
  }

  .transactions-list__column--detail > span {
    font-size: 12px;
  }

  .transactions-list__column hr {
    border-bottom: 1px solid #f0f0f0;
    margin: 0.5em 0;
  }

  .transactions-list__item--canceled .transactions-list__column span {
    color: #bbb;
  }

  .transactions-list__column span.transactions-list__column-empty-detail {
    color: #999;
  }

  .transactions-list__item--canceled .transactions-list__column span.transactions-list__column-canceled-detail {
    color: #333;
  }

  .transactions-list__pagination {
    margin-bottom: 10px;
  }

  .transactions-list__csv {
    margin-bottom: 10px;
  }

  @media (min-width: 576px) {
    .transactions-filter__field {
      display: flex;
      align-items: center;
    }

    .transactions-filter__field label {
      margin-right: 10px;
      margin-bottom: 0;
    }

    .transactions-filter__field-container + .transactions-filter__field-container {
      margin-top: 0;
      margin-left: 10px;
    }
  }

  @media (min-width: 640px) {
    .transactions-filter {
      display: flex;
      flex-wrap: wrap;
      margin-left: -8px;
      margin-right: -8px;
    }

    .transactions-filter__field {
      margin-left: 8px;
      margin-right: 8px;
    }

    .transactions-filter__field--uid {
      max-width: 340px;
    }

    .transactions-filter__field--date {
      max-width: 340px;
      margin-right: auto;
    }

    .transactions-filter__field:last-of-type {
      margin-right: auto;
    }

    .transactions-filter__buttons button {
      padding-left: 20px;
      padding-right: 20px;
    }

    .transactions-list {
      display: table;
      width: 100%;
      max-width: 1000px;
      margin-top: 40px;
    }

    .transactions-list__item {
      display: table-row;
    }

    .transactions-list__item:hover {
      background-color: #f8f8f8;
    }

    .transactions-list__item:hover .transactions-list__column,
    .transactions-list__item:hover + .transactions-list__item .transactions-list__column {
      border-top-color: var(--main-color);
    }

    .transactions-list__item:last-child .transactions-list__column {
      border-bottom: 1px solid #fafafa;
    }

    .transactions-list__item:last-child:hover .transactions-list__column {
      border-bottom-color: var(--main-color);
    }

    .transactions-list__item .transactions-list__column:before {
      display: none;
    }

    .transactions-list__item:first-child .transactions-list__column:before {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 110%;
      padding: 0 15px;
    }

    .transactions-list__column {
      display: table-cell;
      position: relative;
    }

    .transactions-list__column--id {
      border-top: 1px solid #fafafa;
    }

    .transactions-list__column--points {
      text-align: right;
    }

    .transactions-list__bottom {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }

    .transactions-list__pagination {
      margin-bottom: 0;
    }

    .transactions-list__csv {
      margin-left: auto;
      margin-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    .transactions-filter__buttons button {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
</style>
