import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  async getSettings (store) {
    Vue.$logger.debug('admin-settings | Getting site settings')

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/settings'

    const { data } = await axios.get(ACTION_URL, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-settings | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else {
            throw new Error('site_settings_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-settings', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })

    return data.user_options
  }
}
