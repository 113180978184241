<template>
  <div>
    <div class="alert alert--danger" v-if="error">
      <p v-html="error"></p>
    </div>
    <form class="orders-filter">
      <div class="orders-filter__field orders-filter__field--uid">
        <label>Идентификатор</label>
        &nbsp;
        <div class="orders-filter__field-container">
          <input class="form-field form-field--large form-field--full-width" type="text" v-model="filter.searchUid" placeholder="">
        </div>
      </div>
      <div class="orders-filter__field orders-filter__field--date">
        <label>Дата</label>
        <div class="orders-filter__field-container">
          <datepicker
            input-class="form-field form-field--large form-field--full-width"
            calendar-class="form-calendar"
            placeholder="с"
            :language="ru"
            full-month-name
            format="dd.MM.yyyy"
            monday-first
            v-model="filter.dateFrom"
          />
        </div>
        <div class="orders-filter__field-container">
          <datepicker
            input-class="form-field form-field--large form-field--full-width"
            calendar-class="form-calendar"
            placeholder="по"
            :language="ru"
            full-month-name
            format="dd.MM.yyyy"
            monday-first
            v-model="filter.dateTo"
          />
        </div>
      </div>
      <div class="orders-filter__buttons">
        <button type="submit" class="button button--large button--rounded" @click="applyFilter()">Применить</button>
        <button type="reset" class="button button--large button--rounded button--transparent" @click="reset()">Сбросить</button>
      </div>
    </form>
    <div class="orders-list" v-if="list.items.length > 0">
      <div v-for="(item, index) in list.items" :key="index" class="orders-list__item" :class="{ 'orders-list__item--canceled': item.status === 'canceled' }">
        <div class="orders-list__column orders-list__column--id" data-label="ID"><span>{{ item.id }}</span></div>
        <div class="orders-list__column orders-list__column--uid" data-label="UID"><span><a href="" title="Показать информацию о пользователе" @click.prevent="showUserDetails(item.uid)">{{ item.uid }}</a></span></div>
        <div class="orders-list__column orders-list__column--date" data-label="Дата"><span>{{ item.created_date | date }}</span></div>
        <div class="orders-list__column orders-list__column--status" :class="' orders-list__column--' + getOrderStatusColor(item.status)" data-label="Статус"><span>{{ getOrderStatusLabel(item.status) }}</span></div>
        <div class="orders-list__column orders-list__column--controls" data-label="">
          <div class="order-list__controls">
            <button class="order-list__control order-list__control--detail" @click.prevent="showOrderDetails(item.id)" title="Показать информацию о заказе"><font-awesome-icon icon="info-circle" /><span>Подробнее</span></button>
            <button class="order-list__control order-list__control--cancel" v-if="item.status === 'new'" @click="cancel(item)"><font-awesome-icon icon="ban" /><span>Отменить</span></button>
          </div>
        </div>
      </div>
    </div>

    <div class="orders-list" v-if="list.items.length === 0">Список заказов пуст</div>

    <div class="orders-list__bottom" v-if="list.items.length > 0">
      <div class="orders-list__csv">
        <button class="button button--small button--rounded" @click="downloadCsv" :disabled="loadingCsv">Скачать CSV</button>
      </div>

      <div class="orders-list__pagination">
        <Pagination :page="list.page" :pagesTotal="list.pagesTotal" :pageSize="list.pageSize" :itemsTotal="list.itemsTotal" :onPageChange="pageChanged" />
      </div>
    </div>

    <AdminOrderDetails ref="adminOrderDetails" />
    <AdminUserDetails ref="adminUserDetails" />
  </div>
</template>

<script>
import Vue from 'vue'
import adminOrder from '../api/admin-order'
import Swal from 'sweetalert2'
import appError from 'base/modules/app-error'
import utils from 'base/modules/utils'
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'
import AdminOrderDetails from 'base/components/AdminOrderDetails'
import AdminUserDetails from 'base/components/AdminUserDetails'
import Pagination from 'base/components/Pagination'

Vue.component('datepicker', Datepicker)

export default {
  name: 'AdminOrdersList',
  components: {
    AdminOrderDetails,
    AdminUserDetails,
    Pagination
  },
  data () {
    return {
      ru: ru,
      filter: {
        searchUid: '',
        dateFrom: '',
        dateTo: ''
      },
      list: {
        page: 0,
        pagesTotal: 0,
        pageSize: 0,
        itemsTotal: 0,
        items: []
      },
      pagination: {
        page: 1,
        pageSize: 20
      },
      loading: false,
      loadingCsv: false,
      error: ''
    }
  },
  filters: {
    numberFormat: function (number, decimals, decimalPoint, thousandsSeparator) {
      return utils.numberFormat(number, decimals, decimalPoint, thousandsSeparator)
    },
    date: function (date) {
      const dateObj = new Date(date)
      return utils.formatDateTime(dateObj)
    }
  },
  methods: {
    getData () {
      this.loading = true

      adminOrder.getAll(this.$store, this.getFilter()).then(response => {
        this.loading = false
        this.list.page = response.data.page
        this.list.pagesTotal = response.data.pages_total
        this.list.pageSize = response.data.page_size
        this.list.itemsTotal = response.data.items_total

        if (response.data.items !== null) {
          this.list.items = response.data.items.map(item => {
            item.show = false
            return item
          })
        } else {
          this.list.items = []
        }
      }).catch((error) => {
        this.loading = false
        this.error = appError.getMessage(error.message)

        Vue.$logger.debug('AdminOrdersList | adminOrder.getAll | ошибка: ', error)
      })
    },
    getFilter () {
      const filter = {
        limit: this.pagination.pageSize,
        offset: (this.pagination.page - 1) * this.pagination.pageSize
      }

      if (this.filter.searchUid !== '') {
        filter.uid = this.filter.searchUid
      }

      if (this.filter.dateFrom !== '') {
        filter.date_from = utils.formatDateForRequest(this.filter.dateFrom)
      }

      if (this.filter.dateTo !== '') {
        filter.date_to = utils.formatDateForRequest(this.filter.dateTo)
      }

      return filter
    },
    applyFilter () {
      this.resetPagination()
      this.getData()
    },
    resetPagination () {
      this.pagination = {
        page: 1,
        pageSize: 20
      }
    },
    resetFilter () {
      this.filter = {
        searchUid: '',
        dateFrom: '',
        dateTo: ''
      }
    },
    reset () {
      this.resetFilter()
      this.resetPagination()

      this.getData()
    },
    pageChanged (page) {
      this.pagination.page = page
      this.getData()
    },
    downloadCsv () {
      const currentFilter = { ...this.getFilter() }
      currentFilter['limit'] = 100000
      currentFilter['offset'] = 0
      this.loadingCsv = true
      adminOrder.getCsv(this.$store, currentFilter).then(response => {
        this.loadingCsv = false
        utils.downloadFile(response.data, 'orders.csv')
      }).catch((error) => {
        this.loadingCsv = false
        this.error = appError.getMessage(error.message)

        Vue.$logger.debug('AdminOrdersList | adminOrder.getCsv | ошибка: ', error)
      })
    },
    cancel (order) {
      Swal.fire({
        title: 'Отменить заказ?',
        type: 'question',
        input: 'textarea',
        inputPlaceholder: 'Введите комментарий',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        confirmButtonClass: 'button button--confirm button--rounded button--large button--margined',
        cancelButtonClass: 'button button--cancel button--rounded button--large button--margined',
        buttonsStyling: false
      }).then((result) => {
        this.error = ''

        if (typeof result.value !== 'undefined') {
          adminOrder.cancel(this.$store, order.id, result.value).then(() => {
            this.getData()
          }).catch((error) => {
            this.error = appError.getMessage(error.message)

            Vue.$logger.debug('AdminOrdersList | adminOrder.cancel | ошибка: ', error)
          })
        }
      })
    },
    showOrderDetails: function (id) {
      this.$refs.adminOrderDetails.showOrderDetails(id)
    },
    showUserDetails: function (id) {
      this.$refs.adminUserDetails.showUserDetails(id)
    },
    getOrderStatusLabel (status) {
      switch (status) {
        case 'new':
          status = 'Ожидает подтверждения'
          break
        case 'pending':
          status = 'Обрабатывается'
          break
        case 'processing':
          status = 'Обрабатывается'
          break
        case 'checking':
          status = 'Обрабатывается'
          break
        case 'done':
          status = 'Завершён'
          break
        case 'canceled':
          status = 'Отменён'
          break
      }
      return status
    },
    getOrderStatusColor (status) {
      let color
      switch (status) {
        case 'done':
          color = 'green'
          break
        case 'error':
          color = 'red'
          break
        case 'cancel':
          color = 'grey'
          break
        case 'new':
        case 'pending':
        case 'processing':
        case 'checking':
          status = 'black'
          break
      }
      return color
    },
    afterMounted: function () {
    }
  },
  created: function () {
    this.getData()
  },
  mounted: function () {
    this.afterMounted()
  }
}
</script>

<style lang="css" scoped>
  .orders-filter {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
  }

  .orders-filter__field {
    margin-bottom: 10px;
  }

  .orders-filter__field-container + .orders-filter__field-container {
    margin-top: 10px;
  }

  .orders-filter__field label {
    white-space: nowrap;
  }

  .orders-filter__buttons {
    display: flex;
    justify-items: center;
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: 10px;
  }

  .orders-filter__buttons button {
    margin-left: 6px;
    margin-right: 6px;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1;
  }

  .orders-list {
    margin-bottom: 20px;
  }

  .orders-list__item {
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 3px solid #f6f6f6;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .orders-list__column {
    padding: 5px 15px;
  }

  .orders-list__column + .orders-list__column {
    border-top: 1px solid #fafafa;
  }

  .orders-list__column:before {
    content: attr(data-label);
    display: block;
    color: #999;
    font-size: 12px;
  }

  .orders-list__column > span {
    display: block;
  }

  .orders-list__column--uid > span {
    font-weight: normal;
    font-size: 20px;
    line-height: 1.2;
  }

  .orders-list__column--status > span {
    font-size: 12px;
  }

  .orders-list__column--green > span {
    color: #090;
  }

  .orders-list__column--red > span {
    color: #c00;
  }

  .orders-list__column--controls {
    padding: 0;
  }

  .orders-list__column--controls > span {
    font-size: 12px;
  }

  .orders-list__column hr {
    border-bottom: 1px solid #f0f0f0;
    margin: 0.5em 0;
  }

  .orders-list__item--canceled .orders-list__column > span {
    color: #bbb;
  }

  .orders-list__column span.orders-list__column-empty-detail {
    color: #999;
  }

  .order-list__controls {
    display: flex;
    align-items: stretch;
    background-color: #fefefe;
    margin-top: -1px;
    min-height: 44px;
  }

  .order-list__controls--empty {
    display: none;
  }

  .order-list__control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: #fafafa;
    cursor: pointer;
    padding: 10px 0;
    font-size: 16px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.2s, background-color 0.2s;
  }

  .order-list__control:only-child {
    width: 100%;
  }

  .order-list__control--detail {
    color: #499;
  }

  .order-list__control--cancel {
    color: #c00;
  }

  .order-list__control span {
    color: #333;
    transition: color 0.2s;
    margin-left: 8px;
  }

  .order-list__control:hover {
    color: #fff;
  }

  .order-list__control--detail:hover {
    background-color: #499;
    color: #fff;
  }

  .order-list__control--cancel:hover {
    background-color: #c00;
    color: #fff;
  }

  .order-list__control:hover span {
    color: #fff;
  }

  .orders-list__pagination {
    margin-bottom: 10px;
  }

  .orders-list__csv {
    margin-bottom: 10px;
  }

  @media (min-width: 576px) {
    .orders-filter__field {
      display: flex;
      align-items: center;
    }

    .orders-filter__field label {
      margin-right: 10px;
      margin-bottom: 0;
    }

    .orders-filter__field-container + .orders-filter__field-container {
      margin-top: 0;
      margin-left: 10px;
    }
  }

  @media (min-width: 640px) {
    .orders-filter {
      display: flex;
      flex-wrap: wrap;
      margin-left: -8px;
      margin-right: -8px;
    }

    .orders-filter__field {
      margin-left: 8px;
      margin-right: 8px;
    }

    .orders-filter__field--uid {
      max-width: 340px;
    }

    .orders-filter__field--date {
      max-width: 340px;
      margin-right: auto;
    }

    .orders-filter__field:last-of-type {
      margin-right: auto;
    }

    .orders-filter__buttons button {
      padding-left: 20px;
      padding-right: 20px;
    }

    .orders-list__pagination {
      margin-bottom: 0;
    }

    .orders-list__csv {
      margin-left: auto;
      margin-bottom: 0;
    }
  }

  @media (min-width: 800px) {
    .orders-filter__buttons button {
      padding-left: 30px;
      padding-right: 30px;
    }

    .orders-list {
      display: table;
      width: 100%;
      max-width: 1000px;
      margin-top: 40px;
    }

    .orders-list__item {
      display: table-row;
    }

    .orders-list__item:hover {
      background-color: #f8f8f8;
    }

    .orders-list__item:hover .orders-list__column,
    .orders-list__item:hover + .orders-list__item .orders-list__column {
      border-top-color: var(--main-color);
    }

    .orders-list__item:last-child .orders-list__column {
      border-bottom: 1px solid #fafafa;
    }

    .orders-list__item:last-child:hover .orders-list__column {
      border-bottom-color: var(--main-color);
    }

    .orders-list__item .orders-list__column:before {
      display: none;
    }

    .orders-list__item:first-child .orders-list__column:before {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 110%;
      padding: 0 15px;
    }

    .orders-list__column {
      display: table-cell;
      position: relative;
    }

    .orders-list__column--id {
      border-top: 1px solid #fafafa;
    }

    .order-list__controls {
      margin-left: auto;
      width: 280px;
    }

    .order-list__controls--empty {
      display: flex;
    }

    .order-list__control {
      flex-flow: column;
      padding: 8px 20px;
    }

    .order-list__control span {
      margin-left: 0;
    }

    .orders-list__bottom {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    }
  }

  @media (min-width: 992px) {
    .order-list__controls {
      margin-left: auto;
      width: 320px;
    }

    .order-list__controls--empty {
      display: flex;
    }

    .order-list__control {
      flex-flow: row;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .order-list__control span {
      margin-left: 8px;
    }
  }
</style>
