import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  getAll (store, filter) {
    Vue.$logger.debug('admin-user | Getting all users', filter)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/users'
    // const ACTION_URL = '/pseudo-api/users.json'

    return axios.get(ACTION_URL, { headers: store.getters['user/authHeader'](), params: filter })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-user | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else {
            throw new Error('users_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-user', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  getAllCsv (store, filter) {
    Vue.$logger.debug('admin-user | Getting CSV-list of all users', filter)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/users'
    // const ACTION_URL = '/pseudo-api/users.csv'

    return axios.get(ACTION_URL, { headers: { Accept: 'text/csv', ...store.getters['user/authHeader']() }, params: filter })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-user | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else {
            throw new Error('users_get_csv_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-user', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  activate (store, id) {
    Vue.$logger.debug('admin-user | Activating user; id = ', id)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/user/' + id

    return axios.patch(ACTION_URL, { active: 1 }, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-user | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 400) {
            if (error.response.data.message === 'role: expected role to be one of: user, manager, got admin.') {
              throw new Error('user_activate_cannot_if_admin')
            } else {
              throw new Error('user_activate_error')
            }
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('user_not_found')
          } else {
            throw new Error('user_activate_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-user', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  deactivate (store, id) {
    Vue.$logger.debug('admin-user | Deactivating user; id = ', id)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/user/' + id

    return axios.patch(ACTION_URL, { active: 0 }, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-user | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 400) {
            if (error.response.data.message === 'role: expected role to be one of: user, manager, got admin.') {
              throw new Error('user_deactivate_cannot_if_admin')
            } else {
              throw new Error('user_deactivate_error')
            }
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('user_not_found')
          } else {
            throw new Error('user_deactivate_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-user', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  getTasks (store, id) {
    Vue.$logger.debug('admin-user | Getting users tasks. UID: ' + id)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/user/' + id + '/tasks'

    return axios.get(ACTION_URL, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-user | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('user_not_found')
          } else {
            throw new Error('user_tasks_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-user', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  getUser (store, id) {
    Vue.$logger.debug('admin-user | Get user; id = ', id)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/user/' + id

    return axios.get(ACTION_URL, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-user | ' + error.response.status + ' ' + error.response.statusText)
          throw new Error('server_error')
        } else if (error.request) {
          Vue.$logger.error('admin-user', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  editUser (store, id, data) {
    Vue.$logger.debug('admin-user | Edit user; id = ', id)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/user/' + id

    return axios.patch(ACTION_URL, data, { headers: store.getters['user/authHeader']() })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-user | ' + error.response.status + ' ' + error.response.statusText)
          throw new Error('server_error')
        } else if (error.request) {
          Vue.$logger.error('admin-user', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
