<template>
  <div class="modal micromodal-slide" id="user-details" aria-hidden="true" v-if="data !== null">
    <div class="modal__overlay" tabindex="-1" data-micromodal-close>
      <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
        <div class="modal__wrapper">
          <header class="modal__header">
            <h2 class="modal__title">Информация о пользователе <span v-if="!error">{{ data.uid }}</span></h2>
            <button class="modal__close" aria-label="Close modal" data-micromodal-close></button>
          </header>
          <main class="modal__content">
            <div class="alert alert--danger" v-if="error">
              <p v-html="error"></p>
            </div>
            <div v-if="!error" class="user-details">
              <div class="user-details__row">
                <span>UID: </span>
                <strong>{{ data.uid }}</strong>
              </div>
              <div class="tasks-list__task-data-item" v-if="typeof data.meta !== 'undefined' && Object.keys(data.meta).length">
                <div>Фамилия: <b>{{ data.meta.lastName }}</b></div>
                <div>Имя: <b>{{ data.meta.firstName }}</b></div>
                <div>Отчество: <b>{{ data.meta.secondName }}</b></div>
                <div>Дата рождения: <b>{{ data.meta.birthDate }}</b></div>
                <div>Контактный телефон: <b>{{ data.meta.phone }}</b></div>
                <div>Email: <b>{{ data.meta.email }}</b></div>
                <div>Серия паспорта: <b>{{ data.meta.passportSeries }}</b></div>
                <div>Номер паспорта: <b>{{ data.meta.passportNumber }}</b></div>
                <div>Кем выдан: <b>{{ data.meta.passportIssuedBy }}</b></div>
                <div>Когда выдан: <b>{{ data.meta.passportIssuedDate }}</b></div>
                <div>Адрес прописки: <b>{{ data.meta.passportAddress }}</b></div>
                <div>Почтовый адрес для отправки (2-НДФЛ): <b>{{ data.meta.postAddress }}</b></div>
                <div>ИНН (12 цифр): <b>{{ data.meta.inn }}</b></div>
                <div>СНИЛС (11 цифр): <b>{{ data.meta.snils }}</b></div>
              </div>
              <div v-if="activeTasks && activeTasks.length > 0">
                <hr>
                <h3>Список заданий пользователя</h3>
                <div class="user-details__row">
                  <div class="user-details__tasks">
                    <div v-for="(item, index) in activeTasks" :key="index" class="user-details__tasks-item">
                      <div class="user-details__tasks-column user-details__tasks-column--task-id" data-label="ID"><span>{{ item.id }}</span></div>
                      <div class="user-details__tasks-column user-details__tasks-column--type" data-label="Тип"><span>{{ getTypesLabels()[item.type] }}</span></div>
                      <div class="user-details__tasks-column user-details__tasks-column--date" data-label="Дата создания"><span>{{ item.created_at | date }}</span></div>
                      <div class="user-details__tasks-column user-details__tasks-column--statuses" data-label="Статус"><span>{{ getStatusesLabels()[item.status] }}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import adminUser from '../api/admin-user'
import appError from 'base/modules/app-error'
import utils from 'base/modules/utils'
import MicroModal from 'micromodal'

export default {
  name: 'AdminUserDetails',
  data () {
    return {
      data: null,
      error: ''
    }
  },
  computed: {
    activeTasks () {
      if (typeof this.data.tasks !== 'undefined' && this.data.tasks !== null) {
        return this.data.tasks.filter(item => {
          return item.status !== 'canceled'
        })
      } else {
        return []
      }
    }
  },
  filters: {
    numberFormat: function (number, decimals, decimalPoint, thousandsSeparator) {
      return utils.numberFormat(number, decimals, decimalPoint, thousandsSeparator)
    },
    date: function (date) {
      const dateObj = new Date(date)
      return utils.formatDateTime(dateObj)
    }
  },
  methods: {
    showUserDetails: function (id) {
      adminUser.getTasks(this.$store, id).then(response => {
        this.error = ''
        if (response.data.items.length > 0) {
          this.data = response.data.items[0]
        } else {
          this.data = {
            uid: id
          }
        }
        this.$nextTick(() => {
          this.showModal()
        })
      }).catch((error) => {
        this.error = appError.getMessage(error.message)
        this.data = {}
        Vue.$logger.debug('AdminUserDetails | adminUser.getTasks | ошибка: ', error)
        this.$nextTick(() => {
          this.showModal()
        })
      })
    },
    getStatusesLabels () {
      const statuses = {
        pending: 'Новое',
        checking: 'Проверяется',
        done: 'Выполнено',
        canceled: 'Отменено'
      }

      return statuses
    },
    getTypesLabels () {
      const types = {
        approvement_request: 'Запрос подтверждающих документов',
        act_request: 'Запрос акта',
        user_data_request: 'Запрос данных пользователя'
      }

      return types
    },
    showModal: function () {
      MicroModal.show('user-details', {
        onShow: modal => {
          document.body.style.position = 'fixed'
          document.body.style.width = '100%'
        },
        onClose: modal => {
          document.body.style.position = 'initial'
          document.body.style.width = 'auto'
        },
        openTrigger: 'data-micromodal-open',
        closeTrigger: 'data-micromodal-close',
        disableScroll: true,
        disableFocus: false,
        awaitCloseAnimation: false,
        debugMode: true
      })
    },
    closeModal: function () {
      MicroModal.close('user-details')
    }
  },
  created: function () {
  },
  mounted: function () {
    this.$on('showOrderDetails', event => {
    })
  }
}
</script>

<style lang="css">
  .user-details {
    font-size: 14px;
  }

  .user-details__tasks {
    margin-bottom: 20px;
  }

  .user-details__tasks-item {
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 3px solid #f6f6f6;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .user-details__tasks-column {
    padding: 5px 15px;
  }

  .user-details__tasks-column + .user-details__tasks-column {
    border-top: 1px solid #fafafa;
  }

  .user-details__tasks-column:before {
    content: attr(data-label);
    display: block;
    color: #999;
    font-size: 12px;
  }

  .user-details__tasks-column > span {
    display: block;
  }

  .user-details__tasks-column--uid > span {
    font-weight: normal;
    font-size: 20px;
    line-height: 1.2;
  }

  .user-details__tasks-column--detail > span {
    font-size: 12px;
  }

  .user-details__tasks-column hr {
    border-bottom: 1px solid #f0f0f0;
    margin: 0.5em 0;
  }

  .user-details__tasks-item--canceled .user-details__tasks-column span {
    color: #bbb;
  }

  .user-details__tasks-column span.user-details__tasks-column-empty-detail {
    color: #999;
  }

  .user-details__tasks-item-status {
    font-size: 12px;
    padding: 2px 0;
  }

  .user-details__tasks-item-status + .user-details__tasks-item-status {
    border-top: 1px solid #f0f0f0;
  }

  @media (min-width: 640px) {
    .user-details__tasks {
      display: table;
      width: 100%;
      max-width: 1000px;
      margin-top: 40px;
    }

    .user-details__tasks-item {
      display: table-row;
    }

    .user-details__tasks-item:hover {
      background-color: #f8f8f8;
    }

    .user-details__tasks-item:hover .user-details__tasks-column,
    .user-details__tasks-item:hover + .user-details__tasks-item .user-details__tasks-column {
      border-top-color: var(--main-color);
    }

    .user-details__tasks-item:last-child .user-details__tasks-column {
      border-bottom: 1px solid #fafafa;
    }

    .user-details__tasks-item:last-child:hover .user-details__tasks-column {
      border-bottom-color: var(--main-color);
    }

    .user-details__tasks-item .user-details__tasks-column:before {
      display: none;
    }

    .user-details__tasks-item:first-child .user-details__tasks-column:before {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 110%;
      padding: 0 15px;
    }

    .user-details__tasks-column {
      display: table-cell;
      position: relative;
    }

    .user-details__tasks-column--task-id {
      border-top: 1px solid #fafafa;
    }
  }
</style>
