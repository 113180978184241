<template>
  <div class="user-data__form">
    <input
      class="form-field form-field--large"
      type="text"
      v-model="lastName"
      placeholder="Фамилия"
      required
    >
    <div class="row">
      <div class="col-md-6">
        <input
          class="form-field form-field--large"
          type="text"
          v-model="firstName"
          placeholder="Имя"
          required
        >
      </div>
      <div class="col-md-6">
        <input
          class="form-field form-field--large"
          type="text"
          v-model="secondName"
          placeholder="Отчество"
          required
        >
      </div>
    </div>
    <datepicker
      input-class="form-field form-field--large"
      calendar-class="form-calendar"
      placeholder="Дата рождения"
      :language="ru"
      full-month-name
      format="dd.MM.yyyy"
      monday-first
      v-model="birthDate"
      required
    />
    <input
      v-if="isBirthPlace"
      class="form-field form-field--large"
      type="text"
      v-model="birthPlace"
      placeholder="Место рождения"
      required
    >
    <div class="row">
      <div class="col-md-6">
        <masked-input
          class="form-field form-field--large"
          placeholder="Контактный телефон"
          type="tel"
          v-model="phone"
          :mask="['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
          required
        ></masked-input>
      </div>
      <div class="col-md-6">
        <input
          class="form-field form-field--large"
          type="email"
          v-model="email"
          placeholder="Email"
          required
        >
      </div>
    </div>
    <hr>
    <h3>Паспортные данные</h3>
    <br>
    <div class="row">
      <div class="col-md-4">
        <input
          class="form-field form-field--large"
          type="text"
          v-model="passportSeries"
          placeholder="Серия паспорта"
          required
        >
      </div>
      <div :class="{ 'col-md-4': isPassportIssuerCode, 'col-md-8': !isPassportIssuerCode }">
        <input
          class="form-field form-field--large"
          type="text"
          v-model="passportNumber"
          placeholder="Номер паспорта"
          required
        >
      </div>
      <div v-if="isPassportIssuerCode" class="col-md-4">
        <input
          class="form-field form-field--large"
          type="text"
          v-model="passportIssuerCode"
          placeholder="Код подразделения"
          required
        >
      </div>
    </div>
    <input
      class="form-field form-field--large"
      type="text"
      v-model="passportIssuedBy"
      placeholder="Кем выдан"
      required
    >
    <datepicker
      input-class="form-field form-field--large"
      calendar-class="form-calendar"
      placeholder="Когда выдан"
      :language="ru"
      full-month-name
      format="dd.MM.yyyy"
      monday-first
      v-model="passportIssuedDate"
      required
    />
    <!-- <input
      class="form-field form-field--large"
      type="text"
      v-model="passportAddress"
      placeholder="Адрес прописки"
      required
    >
    <hr> -->
    <input
      class="form-field form-field--large"
      type="text"
      v-model="postAddress"
      placeholder="Почтовый адрес для отправки (2-НДФЛ)"
      required
    >
    <div class="row">
      <div class="col-md-6">
        <masked-input
          class="form-field form-field--large"
          placeholder="ИНН (12 цифр)"
          type="text"
          v-model="inn"
          :mask="[/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
          :guide="false"
          placeholderChar="#"
          required
        ></masked-input>
      </div>
      <!-- <div class="col-md-6">
        <masked-input
          class="form-field form-field--large"
          placeholder="СНИЛС (11 цифр)"
          type="text"
          v-model="snils"
          :mask="[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]"
          :guide="false"
          placeholderChar="#"
          required
        ></masked-input>
      </div> -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import MaskedInput from 'vue-text-mask'
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'
import parse from 'date-fns/parse'

Vue.component('masked-input', MaskedInput)
Vue.component('datepicker', Datepicker)

const getInitialState = () => ({
  lastName: '',
  firstName: '',
  secondName: '',
  birthDate: '',
  birthPlace: '',
  phone: '',
  email: '',
  passportSeries: '',
  passportNumber: '',
  passportIssuerCode: '',
  passportIssuedBy: '',
  passportIssuedDate: '',
  passportAddress: '',
  postAddress: '',
  inn: '',
  snils: ''
})

const storeModule = {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    updateLastName: function (state, payload) {
      state.lastName = payload
    },
    updateFirstName: function (state, payload) {
      state.firstName = payload
    },
    updateSecondName: function (state, payload) {
      state.secondName = payload
    },
    updateBirthDate: function (state, payload) {
      state.birthDate = payload
    },
    updateBirthPlace: function (state, payload) {
      state.birthPlace = payload
    },
    updatePhone: function (state, payload) {
      state.phone = payload
    },
    updateEmail: function (state, payload) {
      state.email = payload
    },
    updatePassportSeries: function (state, payload) {
      state.passportSeries = payload
    },
    updatePassportNumber: function (state, payload) {
      state.passportNumber = payload
    },
    updatePassportIssuerCode: function (state, payload) {
      state.passportIssuerCode = payload
    },
    updatePassportIssuedBy: function (state, payload) {
      state.passportIssuedBy = payload
    },
    updatePassportIssuedDate: function (state, payload) {
      state.passportIssuedDate = payload
    },
    // updatePassportAddress: function (state, payload) {
    //   state.passportAddress = payload
    // },
    updatePostAddress: function (state, payload) {
      state.postAddress = payload
    },
    updateInn: function (state, payload) {
      state.inn = payload
    },
    // updateSnils: function (state, payload) {
    //   state.snils = payload
    // },
    setUserData: (state, payload) => {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key]
        if (['birthDate', 'passportIssuedDate'].includes(key)) {
          state[key] = parse(payload[key], 'dd.MM.yyyy', new Date())
        }
        if (['phone'].includes(key)) {
          // console.log('payload[key]', payload[key])
          state[key] = payload[key].substring(1)
        }
      })
    },
    reset: (state) => {
      const initialState = getInitialState()
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    }
  },
  actions: {
    setLastName ({ commit }, payload) {
      commit('updateLastName', payload)
    },
    setFirstName ({ commit }, payload) {
      commit('updateFirstName', payload)
    },
    setSecondName ({ commit }, payload) {
      commit('updateSecondName', payload)
    },
    setBirthDate ({ commit }, payload) {
      commit('updateBirthDate', payload)
    },
    setBirthPlace ({ commit }, payload) {
      commit('updateBirthPlace', payload)
    },
    setPhone ({ commit }, payload) {
      commit('updatePhone', payload)
    },
    setEmail ({ commit }, payload) {
      commit('updateEmail', payload)
    },
    setPassportSeries ({ commit }, payload) {
      commit('updatePassportSeries', payload)
    },
    setPassportNumber ({ commit }, payload) {
      commit('updatePassportNumber', payload)
    },
    setPassportIssuerCode ({ commit }, payload) {
      commit('updatePassportIssuerCode', payload)
    },
    setPassportIssuedBy ({ commit }, payload) {
      commit('updatePassportIssuedBy', payload)
    },
    setPassportIssuedDate ({ commit }, payload) {
      commit('updatePassportIssuedDate', payload)
    },
    // setPassportAddress ({ commit }, payload) {
    //   commit('updatePassportAddress', payload)
    // },
    setPostAddress ({ commit }, payload) {
      commit('updatePostAddress', payload)
    },
    setInn ({ commit }, payload) {
      commit('updateInn', payload)
    },
    // setSnils ({ commit }, payload) {
    //   commit('updateSnils', payload)
    // },
    setUserData ({ commit }, payload) {
      commit('setUserData', payload)
    },
    reset ({ commit }) {
      commit('reset')
    }
  }
}

export default {
  name: 'UserDataForm',
  props: {
    isPassportIssuerCode: {
      type: Boolean,
      default: false
    },
    isBirthPlace: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ru
    }
  },
  computed: {
    lastName: {
      get () {
        return this.$store.state.verification.lastName
      },
      set (value) {
        this.$store.dispatch('verification/setLastName', value)
      }
    },
    firstName: {
      get () {
        return this.$store.state.verification.firstName
      },
      set (value) {
        this.$store.dispatch('verification/setFirstName', value)
      }
    },
    secondName: {
      get () {
        return this.$store.state.verification.secondName
      },
      set (value) {
        this.$store.dispatch('verification/setSecondName', value)
      }
    },
    birthDate: {
      get () {
        return this.$store.state.verification.birthDate
      },
      set (value) {
        this.$store.dispatch('verification/setBirthDate', value)
      }
    },
    birthPlace: {
      get () {
        return this.$store.state.verification.birthPlace
      },
      set (value) {
        this.$store.dispatch('verification/setBirthPlace', value)
      }
    },
    phone: {
      get () {
        return this.$store.state.verification.phone
      },
      set (value) {
        this.$store.dispatch('verification/setPhone', value)
      }
    },
    email: {
      get () {
        return this.$store.state.verification.email
      },
      set (value) {
        this.$store.dispatch('verification/setEmail', value)
      }
    },
    passportSeries: {
      get () {
        return this.$store.state.verification.passportSeries
      },
      set (value) {
        this.$store.dispatch('verification/setPassportSeries', value)
      }
    },
    passportNumber: {
      get () {
        return this.$store.state.verification.passportNumber
      },
      set (value) {
        this.$store.dispatch('verification/setPassportNumber', value)
      }
    },
    passportIssuerCode: {
      get () {
        return this.$store.state.verification.passportIssuerCode
      },
      set (value) {
        this.$store.dispatch('verification/setPassportIssuerCode', value)
      }
    },
    passportIssuedBy: {
      get () {
        return this.$store.state.verification.passportIssuedBy
      },
      set (value) {
        this.$store.dispatch('verification/setPassportIssuedBy', value)
      }
    },
    passportIssuedDate: {
      get () {
        return this.$store.state.verification.passportIssuedDate
      },
      set (value) {
        this.$store.dispatch('verification/setPassportIssuedDate', value)
      }
    },
    // passportAddress: {
    //   get () {
    //     return this.$store.state.verification.passportAddress
    //   },
    //   set (value) {
    //     this.$store.dispatch('verification/setPassportAddress', value)
    //   }
    // },
    postAddress: {
      get () {
        return this.$store.state.verification.postAddress
      },
      set (value) {
        this.$store.dispatch('verification/setPostAddress', value)
      }
    },
    inn: {
      get () {
        return this.$store.state.verification.inn
      },
      set (value) {
        this.$store.dispatch('verification/setInn', value)
      }
    },
    // snils: {
    //   get () {
    //     return this.$store.state.verification.snils
    //   },
    //   set (value) {
    //     this.$store.dispatch('verification/setSnils', value)
    //   }
    // }
  },
  created: function () {
    if (typeof this.$store._modules.root._children.verification === 'undefined') {
      this.$store.registerModule('verification', storeModule)
    }
  }
}
</script>

<style scoped>
  .form-field {
    width: 100%;
    margin-bottom: 15px;
  }

  .form-field--large {
    padding-left: 14px;
    padding-right: 14px;
  }

  @media (min-width: 480px) {
    .form-field--large {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
</style>

<style>
  .user-data__form .vdp-datepicker .form-field {
    width: 100%;
    margin-bottom: 15px;
  }

  .user-data__form .vdp-datepicker .form-field--large {
    padding-left: 14px;
    padding-right: 14px;
  }

  @media (min-width: 480px) {
    .user-data__form .vdp-datepicker .form-field--large {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
</style>
