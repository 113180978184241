import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  get (store, filter) {
    Vue.$logger.debug('admin-transaction | Getting all transactions', filter)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/transactions'
    // const ACTION_URL = '/pseudo-api/transactions.json'

    return axios.get(ACTION_URL, { headers: store.getters['user/authHeader'](), params: filter })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-transaction | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else {
            throw new Error('transactions_get_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-transaction', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  getCsv (store, filter) {
    Vue.$logger.debug('admin-transaction | Getting CSV-list of all transactions', filter)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + store.state.main.code + '/admin/transactions'
    // const ACTION_URL = '/pseudo-api/transactions.csv'

    return axios.get(ACTION_URL, { headers: { Accept: 'text/csv', ...store.getters['user/authHeader']() }, params: filter })
      .then((response) => {
        return response
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-transaction | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else {
            throw new Error('transactions_get_csv_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-transaction', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  upload (app, payload) {
    const files = payload.files
    delete payload.files

    Vue.$logger.info('admin-transaction | Uploading transactions', JSON.parse(JSON.stringify(payload)), files)

    const formData = Object.keys(payload).reduce((formData, key) => {
      formData.append(key, payload[key])
      return formData
    }, new FormData())

    Array.from(files).forEach((file, i) => {
      formData.append('files[]', file)
    })

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + app.$store.state.main.code + '/admin/transactions'

    return axios.post(ACTION_URL, formData, { headers: { ...app.$store.getters['user/authHeader'](), 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        return response.data
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('admin-transaction | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 413) {
            throw new Error('files_size_exceeded')
          } else {
            throw new Error('upload_error')
          }
        } else if (error.request) {
          Vue.$logger.error('admin-transaction', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
