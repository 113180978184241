<template>
  <form action="" method="post" enctype="multipart/form-data">
    <div class="alert alert--danger" v-if="error">
      <p v-html="error"></p>
    </div>
    <div class="alert alert--success" v-if="success">
      <p>{{ success }}</p>
    </div>
    <div v-if="sent === false">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <p>Выберите <a :href="`/examples/${example}.csv`">файл в формате CSV</a> и нажмите кнопку &laquo;Загрузить&raquo;</p>
            <hr>
            <div class="file-field">
              <input
                type="file"
                ref="files"
                @change="handleFilesUpload()"
                accept="text/csv"
              >
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input
              class="form-field form-field--large"
              type="text"
              v-model="code"
              placeholder="Код пополнения"
              maxlength="50"
              required
            >
            <small>Код пополнения должен быть уникальным, <strong>не более 50 символов</strong>.<br> Общий размер файлов <strong>не&nbsp;должен превышать 30&nbsp;МБ</strong>.</small>
          </div>
        </div>
      </div>
      <br>

      <button class="button button--rounded button--large" @click.prevent="submit()">Загрузить</button>

      <div class="row">
        <div class="col-sm-8 col-md-7">
          <div class="form__sending-text" v-if="sending">Пожалуйста, подождите. Идёт отправка...</div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Vue from 'vue'
import adminTransaction from '../api/admin-transaction'
import appError from 'base/modules/app-error'

export default {
  name: 'TransactionsUpload',
  props: {
    example: {
      type: String,
      default: 'transactions'
    }
  },
  data () {
    return {
      files: [],
      code: '',
      sending: false,
      sent: false,
      success: '',
      error: ''
    }
  },
  computed: {
  },
  methods: {
    handleFilesUpload () {
      this.files = this.$refs.files.files
    },
    submit: function () {
      this.error = ''

      this.sending = true

      adminTransaction.upload(this, {
        code: this.code,
        files: this.files
      })
        .then((response) => {
          this.sending = false
          this.success = 'Файлы успешно отправлены.'
          this.sent = true
        }).catch((error) => {
          this.sending = false
          this.error = appError.getMessage(error.message)
          Vue.$logger.debug('AdminTransactionsUpload | tax.upload | ошибка: ', error)
        })
    },
    afterMounted: function () {
    }
  },
  created: function () {
  },
  mounted: function () {
  }
}
</script>

<style lang="css" scoped>
  .form-group {
    margin-bottom: 16px;
  }

  .form-field {
    width: 100%;
    margin-bottom: 15px;
  }

  .form-field--large {
    padding-left: 14px;
    padding-right: 14px;
  }

  @media (min-width: 480px) {
    .form-field--large {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .file-field input {
    width: 100%;
  }

  button {
    margin-bottom: 10px;
  }

  small {
    background: #f9f9f9;
    padding: 16px;
    display: block;
    font-size: 90%;
  }
</style>
