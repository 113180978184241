<template>
  <main>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-8 col-xl-7">
          <div class="container__page">
            <div class="container__content">
              <h1>Загрузка транзакций</h1>
              <ThisComponent></ThisComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AdminTransactionsUpload'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--admin-transactions-upload')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  }
}
</script>
