<template>
  <main>
    <div class="container">
      <div class="container__page">
        <div class="container__content">
          <h1>Статистика заказов</h1>
          <ThisComponent></ThisComponent>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AdminOrdersStatistics'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--admin-order-statistics')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  }
}
</script>
