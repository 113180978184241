<template>
  <div>
    <h1>Редактирование пользователя {{ userUid }}</h1>
    <form>
      <input v-model="active" class="input-checkbox" id="active" type="checkbox" />
      <label class="checkbox" for="active">
        <span>Активен</span>
        <span>
          <svg width="12px" height="10px" viewbox="0 0 12 10">
            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
          </svg>
        </span>
      </label>

      <label>Роль пользователя</label>
      <select
        class="form-field form-field--large"
        type="text"
        v-model="role"
        required
      >
        <option v-for="role in roleList" :value="role" :key="role">{{ role }}</option>
      </select>

<!--      <input-->
<!--        class="form-field form-field&#45;&#45;large"-->
<!--        type="text"-->
<!--        v-model="saleTitle"-->
<!--        placeholder="Название распродажи"-->
<!--        required-->
<!--      >-->

<!--      <input v-model="isStockActive" class="input-checkbox" id="is-active" type="checkbox" />-->
<!--      <label class="checkbox" for="is-active">-->
<!--        <span>Активировать акцию</span>-->
<!--        <span>-->
<!--          <svg width="12px" height="10px" viewbox="0 0 12 10">-->
<!--            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>-->
<!--          </svg>-->
<!--        </span>-->
<!--      </label>-->

      <button @click="showUserMeta" class="button button--rounded button--toggle" type="button">
        {{ isUserMetaEditable ? 'Отменить изменения' : 'Изменить персональные данные'}}
        <font-awesome-icon class="icon" :icon="isUserMetaEditable ? 'caret-up' : 'caret-down'" />
      </button>
      <br>
      <UserDataForm v-if="isUserMetaEditable" :isPassportIssuerCode="isPassportIssuerCode" :isBirthPlace="isBirthPlace" />
      <hr>

      <div class="alert alert--danger" v-if="error">
        <p v-html="error"></p>
      </div>
      <div class="alert alert--success" v-if="success">
        <p v-html="success"></p>
      </div>
      <div class="row">
        <div class="col-sm-8 col-md-7">
          <div class="form__sending-text" v-if="sending">Пожалуйста, подождите. Идёт отправка...</div>
        </div>
      </div>

      <button class="button button--rounded button--large" @click.prevent="submit()" :disabled="sending">Сохранить</button>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import UserDataForm from './UserDataForm'
import adminUser from '../api/admin-user'
import adminSettings from '../api/admin-settings'
import appError from 'base/modules/app-error'
import utils from '../modules/utils'

const getInitialState = () => ({
  role: '',
  active: false
  // options: {
  //   saleTitle: '',
  //   isStockActive: 0
  // }
})

const storeModule = {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    updateRole: (state, payload) => {
      state.role = payload
    },
    updateActiveState: (state, payload) => {
      state.active = +payload
    },
    // updateSaleTitle: (state, payload) => {
    //   state.options.saleTitle = payload
    // },
    // updateStockActiveState: (state, payload) => {
    //   state.options.isStockActive = +payload
    // },
    reset: (state) => {
      const initialState = getInitialState()
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    }
  },
  actions: {
    setRole ({ commit }, payload) {
      commit('updateRole', payload)
    },
    setActiveState ({ commit }, payload) {
      commit('updateActiveState', payload)
    },
    // setSaleTitle ({ commit }, payload) {
    //   commit('updateSaleTitle', payload)
    // },
    // setStockActiveState ({ commit }, payload) {
    //   commit('updateStockActiveState', payload)
    // },
    reset ({ commit }) {
      commit('reset')
    }
  }
}

export default {
  name: 'AdminUserEdit',
  props: {
    isPassportIssuerCode: {
      type: Boolean,
      default: false
    },
    isBirthPlace: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UserDataForm
  },
  data () {
    return {
      isUserMetaEditable: false,
      sending: false,
      success: '',
      error: '',
      user: {},
      userUid: '',
      roleList: []
    }
  },
  computed: {
    userId () {
      return this.$route.params.userId
    },
    role: {
      get () {
        return this.$store.state.userEdit.role
      },
      set (value) {
        this.$store.dispatch('userEdit/setRole', value)
      }
    },
    active: {
      get () {
        return this.$store.state.userEdit.active
      },
      set (value) {
        this.$store.dispatch('userEdit/setActiveState', value)
      }
    }
    // saleTitle: {
    //   get () {
    //     return this.$store.state.userEdit.options.saleTitle
    //   },
    //   set (value) {
    //     this.$store.dispatch('userEdit/setSaleTitle', value)
    //   }
    // },
    // isStockActive: {
    //   get () {
    //     return this.$store.state.userEdit.options.isStockActive
    //   },
    //   set (value) {
    //     this.$store.dispatch('userEdit/setStockActiveState', value)
    //   }
    // }
  },
  methods: {
    async submit () {
      this.error = ''

      this.sending = true

      if (this.isUserMetaEditable) {
        if (this.$store.state.verification.lastName === '') {
          this.error += '<p>Укажите вашу фамилию</p>'
        }

        if (this.$store.state.verification.firstName === '') {
          this.error += '<p>Укажите ваше имя</p>'
        }

        if (this.$store.state.verification.secondName === '') {
          this.error += '<p>Укажите ваше отчество</p>'
        }

        if (this.$store.state.verification.birthDate === '') {
          this.error += '<p>Укажите дату вашего рождения</p>'
        }

        if (this.isBirthPlace && this.$store.state.verification.birthPlace === '') {
          this.error += '<p>Укажите место вашего рождения</p>'
        }

        if (!utils.validatePhone(this.$store.state.verification.phone)) {
          this.error += '<p>Укажите корректный номер телефона</p>'
        }

        if (!utils.validateEmail(this.$store.state.verification.email)) {
          this.error += '<p>Некорректный email</p>'
        }

        if (this.$store.state.verification.passportSeries === '') {
          this.error += '<p>Укажите ваш серию вашего паспорта</p>'
        }

        if (this.$store.state.verification.passportNumber === '') {
          this.error += '<p>Укажите ваш номер вашего паспорта</p>'
        }

        if (this.isPassportIssuerCode && this.$store.state.verification.passportIssuerCode === '') {
          this.error += '<p>Укажите ваш код подразделения паспорта</p>'
        }

        if (this.$store.state.verification.passportIssuedBy === '') {
          this.error += '<p>Укажите кем выдан ваш паспорт</p>'
        }

        if (this.$store.state.verification.passportIssuedDate === '') {
          this.error += '<p>Укажите дату выдачи вашего паспорта</p>'
        }

        // if (this.$store.state.verification.passportAddress === '') {
        //   this.error += '<p>Укажите адрес вашей прописки</p>'
        // }

        if (this.$store.state.verification.postAddress === '') {
          this.error += '<p>Укажите ваш почтовый адрес</p>'
        }

        if (this.$store.state.verification.inn === '') {
          this.error += '<p>Укажите номер вашего ИНН</p>'
        }

        if (this.$store.state.verification.inn !== '' && !utils.validateInn(this.$store.state.verification.inn)) {
          this.error += '<p>Укажите корректный ИНН</p>'
        }

        // if (this.$store.state.verification.snils === '') {
        //   this.error += '<p>Укажите номер вашего СНИЛС</p>'
        // }

        // if (this.$store.state.verification.snils !== '' && !utils.validateSnils(this.$store.state.verification.snils)) {
        //   this.error += '<p>Укажите корректный СНИЛС</p>'
        // }
      }

      if (this.error === '') {
        const data = {
          ...this.$store.state.userEdit
        }

        if (this.isUserMetaEditable) {
          data.meta = {
            ...this.$store.state.verification,
            birthDate: utils.formatDate(this.$store.state.verification.birthDate),
            passportIssuedDate: utils.formatDate(this.$store.state.verification.passportIssuedDate),
            passportSeries: this.$store.state.verification.passportSeries.replace(/[^0-9]/g, ''),
            passportNumber: this.$store.state.verification.passportNumber.replace(/[^0-9]/g, ''),
            phone: this.$store.state.verification.phone.replace(/[^0-9]/g, ''),
            inn: this.$store.state.verification.inn.replace(/[^0-9]/g, ''),
            snils: this.$store.state.verification.snils.replace(/[^0-9]/g, '')
          }

          if (this.isPassportIssuerCode && this.isBirthPlace) {
            data.meta.birthPlace = this.$store.state.verification.birthPlace
            data.meta.passportIssuerCode = this.$store.state.verification.passportIssuerCode
          } else {
            delete data.meta.birthPlace
            delete data.meta.passportIssuerCode
          }
        }

        try {
          await adminUser.editUser(this.$store, this.userId, data)
          this.sending = false
          this.success = 'Сохранено'
        } catch (error) {
          this.sending = false
          this.error = appError.getMessage(error.message)
          Vue.$logger.debug('AdminUserEdit | adminUser.editUser | ошибка: ', error)
        }
      } else {
        this.sending = false
      }
    },
    async getUserInfo () {
      try {
        const { data: user } = await adminUser.getUser(this.$store, this.userId)
        this.user = user
        this.$store.dispatch('userEdit/setActiveState', user.active)
        this.$store.dispatch('userEdit/setRole', user.role)
        // this.$store.dispatch('userEdit/setSaleTitle', user.options.saleTitle ? user.options.saleTitle : '')
        // this.$store.dispatch('userEdit/setStockActiveState', user.options.isStockActive ? user.options.isStockActive : 0)
        this.userUid = user.uid
        if (user.role === 'admin') {
          this.roleList.push('admin')
        }
      } catch (error) {
        this.error = appError.getMessage(error.message)
      }
    },
    showUserMeta () {
      this.isUserMetaEditable = !this.isUserMetaEditable
      if (this.isUserMetaEditable) {
        this.$nextTick(() => {
          this.$store.dispatch('verification/reset')
          this.$store.dispatch('verification/setUserData', this.user.meta)
        })
      }
    },
    afterMounted: function () {
    },
    async getSettings () {
      const userOptions = await adminSettings.getSettings(this.$store)
      this.roleList = Object.keys(userOptions.roles)
    }
  },
  async created () {
    if (typeof this.$store._modules.root._children.userEdit === 'undefined') {
      this.$store.registerModule('userEdit', storeModule)
    }
    this.$store.dispatch('userEdit/reset')
    this.getSettings()
    this.getUserInfo()
  },
  mounted () {
  }
}
</script>

<style lang="css" scoped>
  .form-group {
    margin-bottom: 16px;
  }

  .form-field {
    width: 100%;
    margin-bottom: 15px;
  }

  .form-field--large {
    padding-left: 14px;
    padding-right: 14px;
  }

  @media (min-width: 480px) {
    .form-field--large {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .file-field input {
    width: 100%;
  }

  button {
    margin-bottom: 10px;
  }

  small {
    background: #f9f9f9;
    padding: 16px;
    display: block;
    font-size: 90%;
  }
</style>
