<template>
  <div>
    <div class="alert alert--danger" v-if="error">
      <p v-html="error"></p>
    </div>
    <form class="orders-filter">
      <div class="orders-filter__field orders-filter__field--uid">
        <label>Идентификатор</label>
        &nbsp;
        <div class="orders-filter__field-container">
          <input class="form-field form-field--large form-field--full-width" type="text" v-model="filter.searchUid" placeholder="">
        </div>
      </div>
      <div class="orders-filter__field orders-filter__field--date">
        <label>Дата</label>
        <div class="orders-filter__field-container">
          <datepicker
            input-class="form-field form-field--large form-field--full-width"
            calendar-class="form-calendar"
            placeholder="с"
            :language="ru"
            full-month-name
            format="dd.MM.yyyy"
            monday-first
            v-model="filter.dateFrom"
          />
        </div>
        <div class="orders-filter__field-container">
          <datepicker
            input-class="form-field form-field--large form-field--full-width"
            calendar-class="form-calendar"
            placeholder="по"
            :language="ru"
            full-month-name
            format="dd.MM.yyyy"
            monday-first
            v-model="filter.dateTo"
          />
        </div>
      </div>
      <div class="orders-filter__buttons">
        <button type="submit" class="button button--large button--rounded" @click="applyFilter()">Применить</button>
        <button type="reset" class="button button--large button--rounded button--transparent" @click="reset()">Сбросить</button>
      </div>
    </form>

    <div class="charts" v-if="statistics.length">
      <div class="row">
        <div class="col-md-6">
          <doughnut-chart :chart-data="chartDataCount" :options="chartOptions('По количеству')"></doughnut-chart>
        </div>
        <div class="col-md-6">
          <doughnut-chart :chart-data="chartDataSum" :options="chartOptions('По сумме')"></doughnut-chart>
        </div>
      </div>
      <hr>
      <table class="charts__table">
        <tr>
          <td>Сумма заказов за период: </td>
          <td><strong>{{ totalSum | numberFormat(0, '', ' ') }}</strong> {{ currency }}</td>
        </tr>
        <tr>
          <td>Общее количество: </td>
          <td><strong>{{ totalCount | numberFormat(0, '', ' ') }}</strong> шт.</td>
        </tr>
      </table>

      <div class="charts__message" v-if="!chartDataCount">Нет статистики для отображения</div>
    </div>

    <div v-if="statistics.length === 0">
      Нет данных для отображения статистики. Выберите другой период.
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import adminOrder from '../api/admin-order'
import appError from 'base/modules/app-error'
import utils from 'base/modules/utils'
import Datepicker from 'vuejs-datepicker'
import { ru } from 'vuejs-datepicker/dist/locale'
import DoughnutChart from 'base/components/DoughnutChart'
import { mapGetters } from 'vuex'
import { currency } from 'base/modules/constants'

Vue.component('datepicker', Datepicker)

export default {
  name: 'AdminOrdersStatistics',
  components: {
    DoughnutChart
  },
  data () {
    return {
      ru: ru,
      filter: {
        searchUid: '',
        dateFrom: '',
        dateTo: ''
      },
      chartDataCount: null,
      chartDataSum: null,
      statistics: [],
      loading: false,
      error: ''
    }
  },
  computed: {
    ...mapGetters({
      region: 'main/region',
    }),
    currency () {
      return currency[this.region]
    },
    titles: function () {
      return this.statistics.map(item => {
        return item.title
      })
    },
    count: function () {
      return this.statistics.map(item => {
        return item.count
      })
    },
    sum: function () {
      return this.statistics.map(item => {
        return item.sum
      })
    },
    totalSum: function () {
      return this.sum.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      }, 0)
    },
    totalCount: function () {
      return this.count.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      }, 0)
    }
  },
  filters: {
    numberFormat: function (number, decimals, decimalPoint, thousandsSeparator) {
      return utils.numberFormat(number, decimals, decimalPoint, thousandsSeparator)
    },
    date: function (date) {
      const dateObj = new Date(date)
      return utils.formatDateTime(dateObj)
    }
  },
  methods: {
    getData () {
      this.loading = true

      adminOrder.getStatistics(this.$store, this.getFilter()).then(response => {
        this.loading = false

        this.statistics = response.data

        const palette = this.getColorPalette(response.data.length)

        this.chartDataCount = {
          labels: this.titles,
          datasets: [
            {
              backgroundColor: palette,
              data: this.count
            }
          ]
        }

        this.chartDataSum = {
          labels: this.titles,
          datasets: [
            {
              backgroundColor: palette,
              data: this.sum
            }
          ]
        }
      }).catch((error) => {
        this.loading = false
        this.error = appError.getMessage(error.message)

        Vue.$logger.debug('AdminOrdersStatistics | adminOrder.getStatistics | ошибка: ', error)
      })
    },
    chartOptions: function (title) {
      const options = {
        cutoutPercentage: 50,
        legend: {
          position: 'bottom'
        },
        title: {
          display: true,
          fontColor: '#333',
          fontSize: '20',
          fontFamily: getComputedStyle(document.querySelector('.page')).fontFamily,
          fontStyle: getComputedStyle(document.querySelector('.page')).fontStyle,
          text: title
        }
      }
      return options
    },
    getColorPalette (length, shuffle = false) {
      const shift = Math.floor(360 / length)
      let palette = []
      let i = 0
      for (;i < length; i++) {
        palette.push('hsl(' + (i * shift) + ', 80%, 50%)')
      }

      if (shuffle) {
        palette = palette.sort(() => Math.random() - 0.5)
      }

      return palette
    },
    getFilter () {
      const filter = {}

      if (this.filter.searchUid !== '') {
        filter.uid = this.filter.searchUid
      }

      if (this.filter.dateFrom !== '') {
        filter.date_from = utils.formatDateForRequest(this.filter.dateFrom)
      }

      if (this.filter.dateTo !== '') {
        filter.date_to = utils.formatDateForRequest(this.filter.dateTo)
      }

      filter.limit = 999999

      return filter
    },
    applyFilter () {
      this.getData()
    },
    resetFilter () {
      this.filter = {
        searchUid: '',
        dateFrom: '',
        dateTo: ''
      }
    },
    reset () {
      this.resetFilter()

      this.getData()
    },
    afterMounted: function () {
    }
  },
  created: function () {
    const currentDate = new Date()
    const currentDateBefore = new Date()
    this.filter.dateTo = currentDate
    currentDateBefore.setDate(currentDateBefore.getDate() - 7)
    this.filter.dateFrom = currentDateBefore

    this.getData()
  },
  mounted: function () {
    this.afterMounted()
  }
}
</script>

<style lang="css" scoped>
  .orders-filter {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
  }

  .orders-filter__field {
    margin-bottom: 10px;
  }

  .orders-filter__field-container + .orders-filter__field-container {
    margin-top: 10px;
  }

  .orders-filter__field label {
    white-space: nowrap;
  }

  .orders-filter__buttons {
    display: flex;
    justify-items: center;
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: 10px;
  }

  .orders-filter__buttons button {
    margin-left: 6px;
    margin-right: 6px;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1;
  }

  @media (min-width: 576px) {
    .orders-filter__field {
      display: flex;
      align-items: center;
    }

    .orders-filter__field label {
      margin-right: 10px;
      margin-bottom: 0;
    }

    .orders-filter__field-container + .orders-filter__field-container {
      margin-top: 0;
      margin-left: 10px;
    }
  }

  @media (min-width: 640px) {
    .orders-filter {
      display: flex;
      flex-wrap: wrap;
      margin-left: -8px;
      margin-right: -8px;
    }

    .orders-filter__field {
      margin-left: 8px;
      margin-right: 8px;
    }

    .orders-filter__field--uid {
      max-width: 340px;
    }

    .orders-filter__field--date {
      max-width: 340px;
      margin-right: auto;
    }

    .orders-filter__field:last-of-type {
      margin-right: auto;
    }

    .orders-filter__buttons button {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (min-width: 800px) {
    .orders-filter__buttons button {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .charts__table {
    margin-bottom: 20px;
  }

  .charts__table td {
    padding: 6px 20px;
  }

  @media (max-width: 479px) {
    .charts__table {
      display: block;
    }

    .charts__table tr {
      display: block;
      margin-bottom: 10px;
    }

    .charts__table td {
      display: block;
      padding: 0 20px;
      margin-bottom: 4px;
    }
  }
</style>
