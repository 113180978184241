<template>
  <main>
    <div class="container">
      <div class="container__page">
        <div class="container__content">
          <h1>Список пользователей</h1>
          <ThisComponent
            :list-params="[
              {
                name: 'email',
                label: 'Email'
              }
            ]"
          ></ThisComponent>
          <br>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ThisComponent from 'base/components/AdminUsersList'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--admin-users-list')

  this.afterMounted()
}

export default {
  components: {
    ThisComponent
  }
}
</script>
